.price {
  & .ant-statistic-content {
    font-family: 'Vazir', 'sans-serif';
    @include set-font(inherit, 600);
  }

  & .ant-statistic {
    font-size: inherit;
  }

  &.isSuccess {
    & .ant-statistic-content,
    & > span {
      color: $color-success;
      text-shadow: 0 2px 4px rgba($color: $color-success, $alpha: 0.3);
    }
  }

  &.discount .ant-statistic-content-value {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: $color-error;
      border-radius: 1px;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }

  & .ant-statistic-content-suffix {
    margin-right: 1rem;
    @include set-font(inherit, 500, inherit);
  }

  &.underline .ant-statistic-content-value {
    position: relative;

    &::after {
      content: '';
      width: 2.7rem;
      height: 5px;
      background-color: #b5d335;
      border-radius: 10px;
      position: absolute;
      bottom: -0.5rem;
      right: -1rem;
    }
  }

  &.inline {
    div {
      display: inline;
      color: #121212B3;
    }
  }
}

