// -------------------- tailwindcss --------------------
@import "~tailwindcss/tailwind.css";

// -------------------- react-toastify --------------------
@import "~react-toastify/dist/ReactToastify.css";
// -------------------- fonts --------------------
@import "./base/font";

// -------------------- abstracts --------------------
@import "./abstracts/variables";
@import "./abstracts/mixins";
@import "./abstracts/functions";
@import "./abstracts/animations";

// -------------------- base --------------------
@import "./base/base";
@import "./base/typography";
@import "./base/utilities";

// -------------------- components --------------------
@import "./components/Dashboard/dashboard";
@import "./components/Wallet/wallet";
@import "./components/Settings/settings";
@import "./components/WebTour/WebTour";
//--------------share---------------
@import "./components/Inputs/input";
@import "./components/Inputs/Upload";
@import "./components/Inputs/ProfileInput";
@import "./components/Menu/menu";
@import "./components/Menu/sidebarmenu";
@import "./components/Footer/Footer";
@import "./components/DefaultFormBox/DefaultFormBox";
@import "./components/Layout/layout";
@import "./components/Cards/coursecardsm";
@import "./components/Cards/coursecardbg";
@import "./components/Buttons/button";
@import "./components/Buttons/link";
@import "./components/Accordion/Accordion";
@import "./components/table/table";
@import "./components/breadcrumbs/BreadCrumbs";
@import "./components/myCourse/myCourse";
@import "./components/Pagination/pagination";
@import "./components/Phase/Phase";
@import "./components/FormResultAlarmPage/FormResultAlarmPage";
@import "./components/Price/price";
@import "./components/CourseCardBanner/courseCardBanner";
@import "./components/Rate/rate";
@import "./components/Modal/modal";
@import "./components/VideoPlayer/VideoPlayer.scss";
@import "./components/DragAndDrop/DragAndDrop";

// -------------------- layout --------------------
@import "./components/profile/profileForm";
@import "./components/resume/WorkExperience";
@import "./components/resume/resume";
@import "./components/resume/skills";
@import "./components/CreateCourse/CreateCourse";
@import "./components/CreateCourse/attribute";
@import "./components/createSeasons/CreateSeason";
@import "./components/CreateContent/CreateContent";
@import "./components/CreateContent/ContentSecondPhase";
@import "./components/codeeditor/CodeeditorBox";
@import "./components/Example/Example";
@import "./components/Quize/Quize";
