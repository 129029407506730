.DragAndDrop {
    width: 100%;
    // margin-bottom: 4rem;
    &__video {
        height: 55vh;
        // border: 0.5rem dashed $color-pop-up;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23FFA44AC4' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 1.5rem;
        margin: 1.6rem auto;
        width: 100%;
        &--noborder {
            height: auto;
        }
    }
    &__imageBox {
        min-height: 29.9rem;
        max-height: 29.9rem;
        width: 100%;
        border-radius: 1.5rem;
        background-color: $color-white-light;
    }
    &__img {
        max-width: 100%;
        min-height: 29.9rem;
        max-height: 29.9rem;
        border-radius: 1.5rem;
        margin: 1.6rem auto;
    }
    &__txt {
        color: $color-primary;
        font-size: 2rem;
        top: 50%;
        width: 100%;
        margin: 0rem auto;
        transform: translateY(-50%);
        text-align: center;
    }
    &__DeleteIcon {
        top: 1rem;
        right: 1rem;
        z-index: 10000;
        color: $color-primary;
        font-size: 2rem;
        box-shadow: 5px 5px 5px rgba($color-primary, 0.5);
    }
}
