.CreateSeasonsForm {
	display: flex;
	flex-direction: column;
	& > .input {
		margin-bottom: 2rem;
	}
	@include respond(small-tablet) {
		width: 100%;
	}

	&__row {
		display: flex;
		align-items: flex-end;
		& .input__mask {
			margin-left: 2rem;
		}
	}

	&__topic {
		& .input {
			margin-left: 1.5rem;
		}
	}

	& .button {
		margin-top: 3rem;
		flex: auto;
		width: 50%;
		align-self: center;
	}
}
.CreateSeasonContent {
	min-height: 8rem;
	transition: all 0.3s;
	&__wrapper {
		// 	background-color: $color-gray;
		padding: 1rem 5rem;
		// 	width: calc(100% + 2px);
		// 	margin-right: -1px;
	}

	&_title {
		font-size: 16px;
	}
	& .isDrag .SeasonsContentItem {
		border-color: $color-primary-light;
	}
}
.SeasonsContentItem {
	padding: 6px 2.5rem 6px 1.5rem;
	background-color: rgba($color: $color-gray, $alpha: 0.5);
	border: 1px solid transparent;
	border-radius: 1.5rem;
	margin-bottom: 8px;
	transition: all 0.3s;
	@include respond(small-tablet) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.CreateSeason {
	&__btn {
		& svg {
			max-width: 20px;
			max-height: 20px;
		}
		& i {
			position: absolute;
			width: 64%;
			height: 2px;
			background-color: $color-text-light;
			transform: rotate(-45deg) scale(0);
			transition: transform 200ms;
		}

		&.lock {
			& i {
				transform: rotate(-45deg) scale(1);
			}
		}
	}

	&__accordion {
		margin-top: 1.5rem;
		background-color: $color-white;
		& .ant-collapse-header {
			padding-right: 3.5rem !important;
		}

		& .ContentSecondPhase__content--icon {
			right: -4px;
		}
		&--drag {
			& .ant-collapse-item {
				border-color: $color-primary-dark;
				background-color: $color-primary;
			}
		}
	}
}
.empthy__season {
	& img {
		max-width: 60rem;
	}

	& p {
		font-size: 2rem;
	}
}
.cancel-icon {
	svg {
		width: 18.5px;
		height: 18.5px;
	}
}
