.accordion {
	padding: 0 1rem;
	margin-top: 3rem;

	& .ant-collapse-item {
		margin-bottom: 2rem;
		border: 1px solid rgba($color: #000000, $alpha: 0.3);
		border-radius: 1.5rem;
		transition: all 350ms;
		&:last-child {
			border-radius: 1.5rem;
		}

		& .ant-collapse-header {
			padding: 2px 16px;
		}
	}

	& .ant-collapse-item-active {
		border-color: transparent;
		box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
	}

	& .ant-collapse-header {
		@include display-flex;
		flex-direction: row-reverse;
		width: 100%;
		min-height: 48px;
		font-weight: 600;
		& > span {
			flex: auto;
			order: 1;
			display: flex;
		}
	}

	& .ant-collapse-extra {
		float: none !important;
		margin-left: 2rem;
	}

	& .ant-collapse-content-box{
		padding: 0;
	}

	&__action {
	}

	&__arrow {
		margin-left: 5px;
		transition: all 300ms;

		&.active {
			transform: rotate(180deg);
		}
	}

	&__title{
		font-weight: 500;

	}

	&__circle {
		& .ant-collapse-header {
			& > span {
				margin-right: 1.5rem;
			}
			&::after {
				width: 14px;
				height: 26px;
				background-color: $color-green-dark;
				border-radius: 20px 0 0 20px;
				position: absolute;
				right: -1px;
			}
		}
	}
	// margin: 20px 0;
	// position: relative;
	// .half__circle {
	// 	width: 25px;
	// 	height: 25px;
	// 	background: #46bea7;
	// 	transform: rotate(-90deg);
	// 	border-radius: 50%;
	// 	right: -12px !important;
	// }
	// .ant-collapse {
	// 	border-color: $color-dark-gray;
	// 	overflow: hidden;
	// 	background-color: #ffffff;
	// 	position: relative;
	// }
	// .ant-collapse-content {
	// 	border-color: #ffffff;
	// }
	// .ant-collapse-icon-position-right
	// 	> .ant-collapse-item
	// 	> .ant-collapse-header {
	// 	padding-right: 20px;
	// }
	// .ant-collapse > .ant-collapse-item {
	// 	border-color: $color-dark-gray;
	// }
	// .ant-collapse-content > .ant-collapse-content-box {
	// 	padding: 16px 0;
	// }
	// .ant-collapse-rtl.ant-collapse
	// 	> .ant-collapse-item
	// 	> .ant-collapse-header
	// 	.ant-collapse-extra {
	// 	top: 7px;
	// 	position: relative;
	// 	.arrow__color {
	// 		color: $color-blue;
	// 	}
	// }
	// .ant-collapse-item.ant-collapse-item-active {
	// 	box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
	// }
}
// .ant-collapse-item.ant-collapse-item-active {
// 	.arrow__color {
// 		transform: rotate(180deg);
// 	}
// }
// .accordion-delete {
// 	position: absolute;
// 	left: 42px;
// 	top: 4px;
// }
