.modal {
	& .ant-modal-content {
		border-radius: 1.5rem;
	}

	& .ant-modal-header {
		color: $color-white;
		border-radius: 1.5rem 1.5rem 4px 4px;
		border-bottom: 2px solid $color-blue-dark;
	}

	& .ant-modal-title {
		font-weight: 600;
		font-size: 1.6rem;
		text-align: center;
	}

	& .ant-modal-close-x {
		height: 54px;
	}

	& .ant-modal-body {
		@include respond(big-phone) {
			padding: 1.5rem;
		}
		@media only screen and (max-width: 340px) {
			padding: 5px;
		}
	}
	@include respond(big-phone) {
		min-width: auto;
	}
}
