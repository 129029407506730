.make__phase {
	justify-content: center;
	align-items: flex-end;
	margin-bottom: 25px;
	&--box {
		display: flex;

		justify-content: center;
		align-items: center;
		flex-direction: column;
		// width: 70px;

		@include respond(small-tablet) {
			justify-content: flex-end;
		}
		p {
			// white-space: nowrap;
			color: #cbccce;
			font-size: 13px;
			text-align: center;
			@include respond(small-tablet) {
				white-space: normal;
				text-align: center;
			}
			@include respond(phone) {
				margin-bottom: 4px;
				font-size: 12px;
			}
		}
	}
	&-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background-color: #fdfdff;
		margin: 1rem 0;
		color: #ffffff;
		font-size: 14px;
		position: relative;
		z-index: 1;
		border: 1px solid rgba($color: $color-text, $alpha: 0.3);

		@include respond(phone) {
			width: 10px;
			height: 10px;
		}
	}

	&-line {
		width: 8.4rem;
		height: 1px;
		margin: 0 2rem 2rem;
		position: relative;
		z-index: 0;

		background-color: rgba(18, 18, 18, 0.3);
		// border-bottom: 1px solid rgba(18, 18, 18, 0.3);
		&:last-child {
			display: none;
		}
		@include respond(tablet) {
			// margin: 0 8px;
			// margin-bottom: -19px;
		}
		@include respond(phone) {
			margin: 0 -1rem 1.5rem;
		}
	}
}
