.setting {
	margin-top: 3.5rem;

	&__header {
		font-size: 2rem;
		font-weight: 700;
		margin-bottom: 2.5rem;
	}

	&__password {
		display: flex;
		flex-direction: column;
		row-gap: 2rem;
		width: 100%;
		max-width: 40rem;
	}
}
