.dashboard {
	// height: auto;

	display: grid;
	grid-template-columns: minmax(36rem, 1fr) 1fr minmax(320px, 1fr);
	gap: 2.4rem 2rem;

	@include respond(laptop) {
		grid-template-columns: minmax(330px, 1fr) 1fr minmax(320px, 1fr);
	}
	@include respond(sm-laptop) {
		grid-template-columns: minmax(315px, 1fr) 1fr;
	}
	@include respond(phone) {
		grid-template-columns: 1fr;
	}

	&-container {
		max-width: 1400px;
		margin: 2rem auto 0;
		padding: 0 2rem;
		width: 100%;
		@include respond(laptop) {
			padding: 0;
		}
	}

	&__box {
		box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
		border-radius: 1.5rem;
		background-color: $color-white;
		display: flex;
		flex-direction: column;
		@include respond(tab-port) {
			&.px-10 {
				padding: {
					left: 1.5rem;
					right: 1.5rem;
				}
			}
			&.py-6 {
				padding: {
					top: 1rem;
					bottom: 1rem;
				}
			}
		}

		&--title {
			@include set-font(16px, 600);
		}
	}

	&__course {
		grid-column: 1/3;

		& > h2 {
			@include set-font(1.8rem, 700, $color-primary);
			margin-bottom: 18px;
		}

		&--box {
			min-height: 20rem;
		}
		&--empty {
			@include d-flex-column(center);
			flex: 1 1 auto;

			& p {
				text-align: center;
				margin: 8px 0;
				line-height: 1.7;
			}

			& .button {
				min-width: 19rem;
			}
		}

		&--item {
			border: 1px solid $color-dark-gray;
			border-radius: 1.5rem;
			padding: 1rem 1.5rem;
			margin-top: 1.5rem;
			@include respond(phone) {
				flex-direction: column;
				padding: 1rem;
			}
			&-cover {
				max-width: 16rem;
				max-height: 16rem;
				border-radius: 1rem;
				overflow: hidden;
				@include respond(phone) {
					max-width: 100%;
					max-height: 25rem;
				}
			}
			&-content {
				align-self: center;
				flex: 1 1 auto;
				margin-right: 2rem;
				@include respond(phone) {
					margin-right: 0;
					margin-top: 1.5rem;
					width: 100%;
					padding: 0 1rem;
				}
				@media only screen and (max-width: 320px) {
					padding: 0;
				}
				& > h2 {
					@include set-font(16px, 600, $color-primary);
					margin-bottom: 1.5rem;
				}
			}

			&-info {
				display: grid;
				grid-template-columns: auto auto;
				row-gap: 1rem;
				// justify-content: space-;
				// @include respond(big-phone) {
				// 	grid-template-columns: auto;

				// 	& #student {
				// 		grid-row: 2/3;
				// 	}
				// }
				& > div,
				& .price .ant-statistic-content {
					color: $color-text-light;
					@include respond(big-phone) {
						font-size: 12px;
					}
				}
				& svg {
					max-width: 3rem;
					max-height: 3rem;
					& path {
						stroke-width: 1px;
						stroke: $color-text-light;
					}
				}

				& > a {
					color: $color-primary;
					&:hover {
						color: $color-primary-dark;
					}
				}
			}
		}
	}

	&__income {
		grid-column: 3/4;
		grid-row: 1/3;
		row-gap: 8px;

		@include respond(sm-laptop) {
			grid-column: 1/2;
			grid-row: 3/4;
		}
		@include respond(big-phone) {
			grid-column: 1/3;
		}
		@include respond(small-tablet) {
			grid-row: 4/5;
			flex-direction: column-reverse;
			row-gap: 12px;
		}
		&--actions {
			padding: 0 2rem;

			&-link {
				@include set-font(14px, 600, $color-primary);
				& img {
					padding: 5px;
					background-color: $color-gray;
					border-radius: 50%;
					max-width: 3.8rem;
					max-height: 3.8rem;
					margin-left: 1rem;
				}
			}
		}
		&--box {
			overflow: hidden;
			flex: 1 1 auto;

			&-header {
				min-height: 150px;
				background-color: $color-blue;
				width: 100%;
				@include d-flex-column(center);
				position: relative;
				overflow: hidden;
				color: $color-primary;
				&::before,
				&::after {
					content: '';
					position: absolute;
					background: url(../../../Assets/Images/Icons/income-bg-dollar.svg)
						no-repeat center/contain;
					opacity: 0.33;
					z-index: 0;
				}

				&::before {
					width: 14rem;
					height: 14rem;
					left: -3rem;
					top: -4rem;
				}
				&::after {
					width: 9rem;
					height: 9rem;
					bottom: -3.2rem;
					right: 1rem;
				}

				& p {
					@include set-font(16px, 600, $color-white);
				}

				& .price {
					position: relative;
					& .ant-statistic-content {
						@include set-font(16px, 700, $color-white);
					}
					&::after {
						content: '';
						width: 100%;
						height: 5px;
						background-color: #b5d335;
						border-radius: 10px;
						position: absolute;
						bottom: -0.8rem;
						right: 0;
					}
				}
			}

			&-body {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 2.8rem 1rem;
				margin: 2.5rem 1rem;
				flex: auto;
				@include respond(phone) {
					margin: 1.5rem 1rem;
				}
				& .link {
					grid-column: 1/-1;
					justify-self: center;
					margin-top: auto;
				}
			}
		}
		&--item {
			@include d-flex-column(center);
			& p {
				@include set-font(16px, 600);
				margin: 1rem 0;
				@include respond(phone) {
					font-size: 14px;
				}
			}
			& .price {
				& .ant-statistic-content {
					@include set-font(16px, 700);
					@include respond(phone) {
						font-size: 14px;
					}
				}
			}
		}
	}

	&__message {
		grid-column: 1/2;
		grid-row: 2/3;
		@include respond(small-tablet) {
			grid-column: 1/3;
		}
		&--box {
			min-height: 31rem;

			& .link {
				align-self: flex-end;
				margin-top: 1rem;
				padding: 0 1.5rem;
				height: 32px;
				min-width: auto;
			}
		}

		&--item {
			padding: 6px 1rem 6px 2rem;
			border-radius: 1.5rem;
			background-color: rgba($color: $color-primary, $alpha: 0.4);
			display: flex;
			align-items: center;

			&-cover {
				min-width: 5rem;
				height: 5rem;
				border-radius: 50%;
				overflow: hidden;
			}
			&-content {
				display: grid;
				grid-template-columns: 1fr auto auto;
				margin-right: 1.5rem;
				gap: 4px 1rem;
				flex: 1 1 auto;

				& .name {
					@include set-font(12px, 600, $color-blue);
					white-space: nowrap;
				}

				& .time {
					@include set-font(12px, 300, $color-text-light);
				}

				& .description {
					grid-column: 1/3;
					display: -webkit-box;
					line-clamp: 1;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					font-size: 12px;
				}
			}
		}
	}
	&__student {
		grid-column: 2/3;
		grid-row: 2/3;
		@include respond(small-tablet) {
			grid-column: 1/3;
			grid-row: 3/4;
		}
		&--item {
			display: flex;
			align-items: center;
			padding: 6px;
			border-bottom: 1px solid $color-dark-gray;
			@include respond(tab-port) {
				padding: 6px 2px;
			}
			&-cover {
				@extend .dashboard__message--item-cover;
			}

			& h4 {
				@include set-font(12px, 600, $color-blue);
			}
			& p {
				@include set-font(12px, 500, $color-text-light);
			}
		}
	}

	&-header {
		&-welcome {
			h2 {
				color: #f68521;
				font-size: 1.8rem;
				font-weight: 700;
			}
		}

		&-tutorial {
			color: #f68521;

			img {
				padding: 0.25rem;
				margin-left: 0.6rem;
				background: rgba(229, 229, 229, 0.6);
				border-radius: 50%;
			}

			&-vid,
			&-pdf {
				h6 {
					color: #f68521;
					font-size: 1.4rem;
					font-weight: 500;
				}
			}
		}
	}
}
