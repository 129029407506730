.wallet {
	margin-top: 3.5rem;
	&-header {
		font-size: 2rem;
		font-weight: 700;
		margin-bottom: 2.5rem;
	}
	&__message {
		font-size: 1.6rem;
		
	}

	&__form {
		& .ant-input {
			text-align: center;
		}

		& .input {
			max-width: 36rem;
			width: 100%;
		}

		&--input {
			border-width: 1px;
			& .ant-input-wrapper {
				width: 100%;
			}

			& .ant-input-group-addon,
			& .ant-input {
				border: 0 !important;
				text-align: left;
				padding-left: 0;
			}

			& .ant-input-group-addon {
				direction: ltr;
			}

			& .ant-input:focus {
				box-shadow: none;
			}
		}
	}
	&__btn {
		align-self: center;
		margin-top: 2rem;
	}
}
.card-form {
	// &-form {
	//   margin-top: 6.2rem;
	//   div {
	//     position: relative;

	//     &:not(:last-child) {
	//       margin-bottom: 5.4rem;
	//     }

	//     &:last-child {
	//       margin-bottom: 6rem;
	//     }
	//     input {
	//       height: 4rem;
	//       width: 33rem;
	//       border: 1px solid rgba(0, 0, 0, 0.2);
	//       border-radius: 1.5rem;
	//     }
	//     label {
	//       position: absolute;
	//       font-size: 1.6rem;
	//       margin-bottom: 1rem;
	//       top: -3rem;
	//       right: 1rem;
	//     }
	//   }
	// }
}

.IncomeListContainer {
	&-header {
		box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
		border-radius: 15px;
		width: 100%;
		min-height: 5rem;
		padding: 0 2rem;
		margin-bottom: 2rem;
		@include respond(tablet) {
			padding: 1.5rem;
		}
		& > div {
			flex-grow: 1;
			font-size: 1.6rem;
			font-weight: 500;
		}
		& svg {
			max-width: 2.8rem;
			width: 100%;
			margin-left: 5px;
		}
	}

	&__wrapper {
		width: 100%;
		
		& .course_table {
			margin: 0;
		}
	}
}
