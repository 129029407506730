.form-wrapper {
	grid-row-gap: 20px;
	grid-column-gap: 15rem;
	margin-right: 13rem;
	margin-left: 8rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	@include respond(sm-laptop) {
		grid-column-gap: 10px;
		margin-right: 3rem;
		margin-left: 3rem;
	}
	@include respond(big-phone) {
		grid-template-columns: 1fr;
		margin-right: 1rem;
		margin-left: 1rem;
	}
}
.profile {
	&__social {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 2rem;
		width: 100%;

		@include respond(sm-laptop) {
			grid-template-columns: 1fr 1fr;
		}
		@include respond(big-phone) {
			grid-template-columns: 1fr;
		}
	}

	&__action {
		@include respond(phone) {
			margin-top: 5rem;
		}
	}
}
