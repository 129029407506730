.appbar {
	&__dahsboard {
		color: #ffffff;
		font-size: 14px;
		@include respond(big-tablet) {
			padding: 0 2rem;
			min-height: 8.5rem;
			display: flex;
			width: 100%;
			flex-direction: column;
			justify-content: center;
		}
		@include respond(phone) {
			padding: 0 1rem;
		}
		&.sticky {
			@include respond(tablet) {
				box-shadow: 0 4px 15px rgba($color: #000000, $alpha: 0.15);
			}
		}
		li.Menu__li {
			padding: 0 2rem;
			@include respond(sm-laptop) {
				padding: 0 1.5rem;
			}
			& a {
				@include set-font(14px, 600, $color-white);
				&:hover {
					color: $color-primary-light;
				}
				// &.active {
				// 	color: $color-primary-light;
				// }
			}
		}
		.logo {
			margin-left: 10px;
		}

		& .Menu-wrapper {
			column-gap: 3rem;
		}
		& .menu_logo {
			margin-right: 12rem;
			justify-content: center;
			@include respond(big-tablet) {
				margin-right: 0;
				order: 2;
			}
			@include respond(tablet) {
				padding-right: 0;
				padding-left: 0;
			}
			& .logo {
				width: 5.8rem;
				height: 5.2rem;
				border-radius: 50%;
				padding: 1rem;
				@include display-flex(center);
				margin-left: 1.5rem;
				@include respond(big-tablet) {
					width: 4.4rem;
					height: 4.4rem;
					margin-left: 1rem;
				}
				@include respond(tablet) {
					margin-left: 0.5rem;
				}

				& img {
					width: 100%;
					height: 100%;
					margin-left: -2px;
				}
			}
			& .logo-text {
				& svg {
					width: 100%;
					transition: all 300ms;
				}
				@include respond(big-tablet) {
					max-width: 10rem;
				}
			}
		}
		& .Menu_actions {
			@include display-flex;
			margin-left: 12rem;
			@include respond(sm-laptop) {
				margin-left: 4rem;
			}
			@include respond(big-tablet) {
				order: 3;
				margin-left: 0;

				justify-content: space-between;

				&--profile {
					flex-direction: row-reverse;
					margin-right: 2rem;
					@include respond(big-tablet) {
						margin-right: 1rem;
					}
					& .profile__image {
						margin-left: 1rem;

						& img {
							width: 40px;
							height: 40px;
						}
					}
				}
				& .link {
					height: 28px;
					font-size: 12px;
				}
			}

			& > .ant-btn {
				margin-left: 1rem;
				@include respond(phone) {
					margin-left: 0;
				}
				& .ant-badge {
					color: #fff;
					transition: all 0.3s 0.1s;
					@include respond(big-tablet) {
						color: $color-text;
					}
				}
				&.activeMenu {
					& .ant-badge {
						color: #fff;
					}
				}
			}
		}

		.Menu__nav {
			// overflow: hidden;
			min-height: 8rem;
			// width: 100%;

			transition: all 400ms ease-in-out;

			@include respond(big-tablet) {
				order: 1;
				position: fixed;
				right: -100vw;
				top: 0;
				padding-top: 8rem;
				padding-right: 0;
				width: 50vw;
				height: 100vh;
				background-color: $color-blue;
				align-items: flex-start;
				flex-direction: column;
				justify-content: flex-start;
				&::before {
					content: none !important;
				}
			}

			@include respond(big-phone) {
				width: 100vw;
			}
			&.active {
				right: 0;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				width: 100vw;
				height: 100%;
				z-index: -1;
				background-color: $color-blue;
				border-bottom-right-radius: 85px;
			}

			&--btn {
				flex-direction: column;
				display: none;
				z-index: 1051;
				@include respond(big-tablet) {
					display: flex;
					width: auto;
				}
				&:focus,
				&:active {
					&::after {
						opacity: 0;
					}
				}
				& span {
					background-color: $color-black;

					width: 2.6rem;
					height: 2px;
					border-radius: 6px;
					transition: all 200ms;

					&:nth-child(2) {
						margin: 4px 0;
					}
				}
				&.open {
					& > span {
						background-color: #fff;
						&:nth-child(2) {
							transform: translateX(2rem);
							opacity: 0;
						}
						&:nth-child(1) {
							transform: rotate(-45deg) translate(-4px, 4px);
						}
						&:nth-child(3) {
							transform: rotate(45deg) translate(-4px, -4px);
						}
					}
				}
			}

			&--wrapper {
				@include display-flex;
				width: 100%;
				@include respond(big-tablet) {
					width: auto;
				}
				@include respond(smallest-phone) {
					flex-basis: auto;
				}
			}

			&--backdrop {
				width: 100vw;
				height: 100vh;
				background-color: rgb(0 0 0 / 50%);
				position: fixed;
				top: 0;
				right: -100vw;
				z-index: 0;
				opacity: 0;
				transition: right 0.3s, opacity 0.4 0.2s ease-in-out;
				&.active {
					right: 0;
					opacity: 1;
				}
			}
		}

		& .Menu__ul {
			width: 100%;
			@include display-flex;
			@include respond(big-tablet) {
				flex-direction: column;
				align-items: flex-start;
				padding-right: 3rem;
				margin-right: 0;
				& .Menu__li {
					&:not(:last-child) {
						margin-bottom: 2.5rem;
					}
				}
			}
			&--sidebar {
				border-bottom: 1px solid $color-blue-dark;
			}
		}

		.profile__image {
			position: relative;
			img {
				width: 44px;
				height: 44px;
				border-radius: 50%;
				overflow: hidden;
				// margin-bottom: 3px;
			}
			&-content {
				// display: none;
				div {
					background-color: #fff;
					color: $color-primary;
					border-radius: 50%;
					top: 25px;
					right: 0;
					padding: 0.1rem 0.2rem;
				}
			}
		}
	}
}
.profile-menu {
	display: flex;
	flex-direction: column;

	background: #fff;
	font-size: 1.4rem;
	font-weight: 700;
	color: #121212;
	justify-content: center;
	font-weight: 400;
	font-size: 1.3rem;

	&-item {
		display: flex;
		align-items: center;
		padding: 0.7rem 0;

		&-icon {
			margin-left: 10px;
			font-weight: 700;
			font-size: 18px;
		}
		&-arrow {
			margin-right: auto;
			font-size: 1rem;
		}
	}
	p.profile__name {
		color: $color-text;
		font-size: 1.6rem;
	}
}
.ant-popover-inner {
	border-radius: 14px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.35);

	overflow: hidden;
	&-content {
		padding: 0;
	}
}

.appbar__profile {
	min-width: 5rem;
	max-height: 5rem;
	position: relative;
	cursor: pointer;
	&.ant-dropdown-open {
		& svg {
			transform: rotate(180deg);
		}
	}
	& .ant-avatar {
		border-radius: $br-item;
		width: 100%;
		height: 100%;
	}

	& .ant-badge-rtl .ant-scroll-number-custom-component {
		bottom: 0;
		right: 0;
		transform: none;
		top: auto;
		left: auto;
		@include display-flex(center);
		width: 20px;
		height: 20px;
		color: $color-primary;
		border-radius: 50%;
		background-color: $color-white;
		font-size: 13px;
		padding: 5px;
		& svg {
			margin-top: 2px;
			transition: all 300ms ease-in 30ms;
		}
	}

	&--menu {
		&.ant-menu {
			min-width: 30rem;
			border-left: 0;
			border-radius: 2rem;
			filter: drop-shadow(0 5px 10px rgba($color: #000, $alpha: 0.16));
			margin-top: 1rem;
			overflow: hidden;
		}
		& .ant-menu-item {
			@include display-flex(space-between);
			flex-direction: row-reverse;
			margin-bottom: 0;
			margin-top: 5px;
			height: 5rem;
			&:hover {
				& .ant-menu-item-icon {
					margin-left: 4px;
				}
			}

			&.information {
				width: 100%;
				height: auto;
				cursor: default;
				margin-top: 0;
				&.ant-menu-item-selected {
					background-color: #fff;
					color: $color-text;
				}
				&:hover {
					color: $color-text;
				}
			}
		}

		& .ant-menu-item-icon {
			margin-right: 0 !important;
			// transform: rotate(90deg);
			max-height: 6px;
		}
		& .ant-menu-title-content {
			@include display-flex;
			column-gap: 1rem;
			width: 100%;

			& > svg {
				max-width: 17px;
			}
		}

		& .ant-menu-item-danger {
			&:hover {
				color: $color-error;
			}
		}
	}

	&--info {
		width: 100%;
		@include display-flex;
		padding: 1.5rem 0;
		column-gap: 1rem;

		& h4 {
			line-height: 1.2;
			@include set-font(1.6rem, 700);
			margin-bottom: 0.6rem;
		}

		&-content {
			line-height: 1.5;
			font-size: 12px;
		}

		& svg {
			width: 100%;
			height: 100%;
			max-width: 21px;
			max-height: 28px;
		}
	}
}
