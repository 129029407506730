.VideoPlayer {
    &__videoBox {
        margin: 1.8rem 0;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }
    &__imgBox {
        background-color: $color-text;
        width: 100%;
        height: 29.9rem;
        border-radius: 1.5rem;
    }
    &__loader {
        background-color: $color-white;
        width: 100%;
        height: 29rem;
        border-radius: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__video {
        background-color: $color-text;
        width: 100%;
        height: 29.9rem;
        border-radius: 1.5rem;
    }
}
