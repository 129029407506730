.FormResultAlarmWrapper {
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  border-radius: 1.5rem;
  padding: 34px;
  flex-direction: column;
  &_h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    width: 70%;
  }
}
.FormResultAlarmWrapper__background {
}

.finished-course {
  margin-top: 9.5rem;
  padding-bottom: 4.6rem;

  h3 {
    line-height: 28px;
    margin-bottom: 2rem;
    width: 28rem;
  }
  p {
    width: 73rem;
  }
  img {
    margin-bottom: 5rem;
  }
}
