.link {
	@extend .button;
	white-space: nowrap;
	text-align: center;
	user-select: none;
	touch-action: manipulation;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

	&__default {
		border: 2px solid $color-primary;
		color: $color-primary;

		&:hover {
			background-color: $color-primary;
			color: $color-white;
			filter: $shadow-button;
		}
		&:active {
			animation: shadow-drop-center 800ms ease;
			color: $color-primary;
		}
	}

	&__primary {
		filter: $shadow-button;
		background-color: $color-primary;
		color: $color-white;

		&:hover {
			color: $color-white;
			background-color: $color-primary-light;
		}

		&:active {
			background-color: $color-primary-dark;
		}
	}

	&__gradient {
		background: $color-gradient;
		min-width: 15rem;
		color: $color-white;
		border: 0;
		&.disabled {
			background: $color-gray;
			&:hover {
				background: $color-gray;
			}
		}
		&:hover,
		&:active {
			background: $color-gradient;
			color: $color-white;
			filter: $shadow-button;
		}
		&:active {
			animation: shadow-drop-center 800ms ease;
		}
	}

	&__text {
		color: $color-primary;
		flex-direction: row;
		column-gap: 1rem;
		position: relative;
		overflow: hidden;
		font-size: 14px;
		&.disabled {
			color: $color-gray-dark;
			background-color: transparent;
			border-color: transparent;
			&::after {
				content: none;
			}
			&:hover,
			&:active {
				color: $color-gray-dark;
				background-color: transparent;
				border-color: transparent;
			}
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			background-color: currentColor;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: all 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
		}

		&.success {
			color: $color-success !important;
		}
		&:hover,
		&:active {
			color: $color-primary;
			background-color: transparent;

			&::after {
				opacity: 0.15;
			}
		}
	}
}
