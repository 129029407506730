.ContentSecondPhase {
	background: #ffffff;
	box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
	border-radius: 15px;
	margin: 5px;
	padding: 20px 0;
	flex: auto;

	&__content {
		//height: 90%;
		transition: all 0.3s;
		.ant-collapse {
			background-color: #f2f2f2;
			border-radius: 0;
			margin-top: 1rem;
			transition: all 0.3s;

			& .ant-collapse-item-active {
				box-shadow: none;
			}
		}
		& .ant-collapse-item {
			margin-bottom: 0;
		}
		.ant-collapse-content {
			border: 0;
		}

		.ant-collapse > .ant-collapse-item {
			border: 0;
		}
		&--drag {
			& .ant-collapse {
				background-color: rgba($color: $color-primary, $alpha: 0.5);
			}
		}

		&--icon {
			width: auto;
			height: auto;
			z-index: 10;
			position: absolute;
			padding: 8px 1rem;
			right: -8px;
			& svg {
				max-width: 2rem;
				max-height: 3rem;
			}
		}
		&--accordion {
			& .ant-collapse-header {
				padding-right: 3.5rem !important;
			}
		}
	}
	&__btnrow {
		width: 80%;
		display: flex;
		justify-content: space-around;
		margin: 0 auto;
		margin-bottom: 20px;
	}
}
.textCaseLable {
	grid-template-columns: 1fr 1fr;
	grid-gap: 40px;
	@include respond(big-phone) {
		display: none;
	}
}
.content__SubmitFrom {
	// background-color: rgba($color: $color-gray, $alpha: 0.5);
	// padding: 3rem 4rem;
	// .IconBox-ab {
	// 	top: 25px;
	// }

	&--example {
		width: 70vw;
		max-width: 100rem;
		max-height: 80vh;
		overflow: auto;
		padding: 0 1rem 1rem 2rem;
		margin-left: -1rem;
		@extend .custom-scrollbars;

		@include respond(laptop) {
			max-height: 70vh;
		}
		@include respond(tab-port) {
			width: 80vw;
		}
		@include respond(big-phone) {
			width: 100%;
		}
	}
	&--description {
		min-width: 80rem;
	}

	&--codeEditor {
		& .fullscreen,
		& .codeeditorBox__BtnBox1 {
			width: 100%;
		}
	}
	// .textCaseInput__row {
	// 	// display: grid;
	// 	// grid-template-columns: 2fr max-content 2fr max-content;
	// 	// align-items: center;
	// 	// grid-gap: 3rem;
	// 	&:not(:last-child) {
	// 		margin-bottom: 1rem;
	// 		@include respond(big-phone) {
	// 			margin-bottom: 2.5rem;
	// 		}
	// 	}
	// 	& .input__field {
	// 		margin-top: 0;
	// 	}

	// 	@include respond(big-phone) {
	// 		grid-template-rows: 1fr 1fr;
	// 		grid-template-columns: auto 5rem;
	// 		row-gap: 1rem;

	// 		& .button__icon {
	// 			grid-column: 2/3;
	// 			grid-row: 1/1;
	// 			align-self: start;
	// 		}
	// 		& .input {
	// 			display: grid;
	// 			grid-template-columns: auto auto;
	// 			gap: 1rem;
	// 			align-items: center;
	// 		}

	// 		& .input__message {
	// 			grid-column: 2/-1;
	// 		}

	// 		& .arrow {
	// 			display: none;
	// 		}
	// 	}

	// 	& .input__label {
	// 		display: none;
	// 		@include respond(big-phone) {
	// 			display: block;
	// 		}
	// 	}
	// }
	& .button__text {
		@include respond(phone) {
			font-size: 12px;
		}
	}
}
.testCase--items-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.gray-bg {
	background-color: $color-gray;
	border-radius: 15px;
	padding: 12px 15px;
	margin: 5px;
}
.gray-Circle {
	background-color: $color-gray;
	border-radius: 50%;
	width: 38px;
	height: 38px;
	svg {
		width: 20px;
	}
}
.ExampleDetail__sampledata {
	background-color: $color-gray-dark;
	border-radius: 1.5rem;
	padding: 0.5rem 1rem;
	color: $color-black-light;
	font-size: 1.6rem;
	resize: none;
	overflow: auto;
	word-wrap: break-word;
	word-break: break-all;
	max-width: 6rem;
	max-height: 8rem;
	&::-webkit-scrollbar {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		opacity: 0;
		border-radius: 1.5rem;
	}
	&:focus {
		outline: none;
	}
}
