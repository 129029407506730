.container-lg {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: 5rem;
  padding-left: 5rem;
  height: inherit;
  max-width: 1920px;

  @include respond(big-desktop) {
    // max-width: 1800px;
  }
  @include respond(phone) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: 3rem;
  padding-left: 3rem;

  @include respond(big-desktop) {
    max-width: 1300px;
  }
  @include respond(laptop) {
    max-width: 1140px;
  }
  @include respond(phone) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.d-flex {
  @include display-flex;
}

.d-flex-col {
  @include d-flex-column;
}

.d-flex-align {
  display: flex;
  align-items: center;
}

.d-flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

.d-flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

.d-flex-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-flex-align-end {
  display: flex;
  align-items: flex-end;
}

.b-radius {
  &-3 {
    border-radius: 3rem;
  }

  &-2 {
    border-radius: 2rem;
  }

  &-1 {
    border-radius: 1rem;
  }
}

.custom-scrollbars {
  scrollbar-color: $color-disable transparent;
  scrollbar-width: thin;
  // overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-disable;
    border-radius: 4px;
  }
}

.full-width {
  width: 100%;
}

.avatar {
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 2rem;
  flex: 0 0 auto;
}

.grid-temp-col-sm {
  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}

.image {
  width: 100%;
  height: 100%;
}

.number {
  @include display-flex(center);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid $color-primary;
  color: $color-primary;
  font-size: 12px;
}

.select_dropdown {
  display: none !important;
}

.ant-skeleton-element {
  width: 100% !important;
}

.ant-skeleton-element .ant-skeleton-button-lg {
  width: 100%;
}
.ace_scrollbar-v {
  &::-webkit-scrollbar {
    opacity: 0;
    border-radius: 1.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    opacity: 0;
    border-radius: 1.5rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    opacity: 0;
    border-radius: 1.5rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    opacity: 0;
    border-radius: 1.5rem;
  }
}
.ace_editor {
  overflow: auto !important;
  // max-height: 52.5rem !important;
  &::-webkit-scrollbar {
    opacity: 0;
    border-radius: 1.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    opacity: 0;
    border-radius: 1.5rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    opacity: 0;
    border-radius: 1.5rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    opacity: 0;
    border-radius: 1.5rem;
  }
}
