.course_table {
	border-radius: 15px;
	box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
	overflow: hidden;
	margin-top: 1rem;
	flex: 1 1 auto;

	& .ant-table-wrapper,
	& .ant-spin-nested-loading,
	& .ant-spin-container {
		height: 100%;
	}

	& .ant-spin-container {
		@include d-flex-column(space-between);
	}

	& .ant-table-tbody {
		& .ant-table-cell {
			& .link,
			.button {
				width: fit-content;
				min-width: auto;
				height: 32px;
				@include respond(big-tablet) {
					font-size: 12px;
				}
			}
			@include respond(big-tablet) {
				font-size: 12px;

				& .price {
					font-size: 12px;
				}
			}
		}
	}

	@include respond(sm-laptop) {
		table,
		tbody,
		tr {
			display: block;
		}

		& table {
			border-spacing: 0 !important;
		}

		& .ant-table {
			width: 100%;
		}

		& .ant-table-thead {
			margin-top: 1rem;

			& .ant-table-cell {
				&:nth-child(7) {
					display: none;
					visibility: hidden;
				}
			}
		}

		& .ant-table-thead,
		& .ant-table-tbody {
			display: flex;
			& tr {
				display: grid;
				grid-template-columns: 5rem 2fr repeat(4, 1fr);
				width: 100%;
			}
		}

		& .ant-table-tbody {
			flex-direction: column;

			& .ant-table-cell {
				&:nth-child(7) {
					grid-column: 1/-1;
					padding-right: 3rem;
					padding-top: 0;
					padding-bottom: 5px;
				}
			}
		}

		& .ant-table-row {
			position: relative;
			&:first-child {
				margin-top: 1rem;
			}
			&:last-child {
				margin-bottom: 2rem;
			}

			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
		& .ant-table-empty {
			& .ant-table-tbody .ant-table-cell {
				grid-column: 1/-1;
			}
		}
	}
	@include respond(phone) {
		& .ant-table-thead {
			display: none;
			visibility: hidden;
		}
		& .ant-table-tbody {
			& tr {
				grid-template-columns: 100%;
			}
			& .ant-table-cell {
				position: relative;
				text-align: right !important;
				&:not(:last-child):not(:first-child) {
					padding-right: 40% !important;
					&:before {
						position: absolute;
						width: 45%;
						padding-right: 10px;
						white-space: nowrap;
						right: 1rem;
					}
				}

				&:nth-of-type(1) {
					display: none;
				}
				&:nth-of-type(7) {
					padding: 0 1rem;
				}

				&:nth-of-type(2) {
					&:before {
						content: 'نام دوره';
					}
				}
				&:nth-of-type(3):before {
					content: 'تعداد جلسات';
				}
				&:nth-of-type(4):before {
					content: 'تعداد دانشجوها';
				}
				&:nth-of-type(5):before {
					content: 'قیمت دوره';
				}
				&:nth-of-type(6):before {
					content: 'وضعیت';
				}
			}
		}
		& .ant-table-row {
			&:first-child {
				margin-top: 0;
			}
		}
		& .ant-table-empty {
			& .ant-table-tbody .ant-table-cell {
				display: block;
			}
		}
	}
	&--actions {
		& > div {
			@include respond(sm-laptop) {
				justify-content: flex-start;
				column-gap: 2rem;
			}
			@include respond(phone) {
				column-gap: 1rem;
			}
		}
	}
}

.table-box {
	& .table__num {
		width: 22px;
		height: 22px;
		border-radius: 50%;
		border: 1px solid $color-primary;
		color: $color-primary;
		@include display-flex(center);
	}
	& .ant-table-thead {
		& .ant-table-cell {
			text-align: center;
			background-color: $color-white;
			border-bottom: 0;
			@include set-font(1.6rem, 600, rgba($color: $color-text, $alpha: 0.8));
			padding: 8px 4px;

			&::before {
				content: none !important;
			}
		}
	}

	& .ant-table-tbody {
		& .ant-table-cell {
			text-align: center;
			background-color: rgba($color: $color-gray, $alpha: 0.3);
			border-bottom: 0;
			padding: 12px 4px;

			&:first-child {
				padding-right: 1.5rem;
			}
			&:last-child {
				padding-left: 1.5rem;
			}
		}
	}

	& .ant-table-row:hover {
		& .ant-table-cell {
			background-color: rgba($color: $color-gray, $alpha: 0.8);
		}
	}

	& table {
		border-spacing: 0 10px;
		table-layout: fixed;
	}

	& .ant-pagination-disabled {
		& > button {
			border-color: $color-disable;
			color: $color-disable;
			opacity: 0.8;
		}
	}

	& .ant-pagination-item-link,
	& .ant-pagination-item {
		border-radius: 50%;
		@include display-flex(center);
		font-family: 'vazir';
	}

	& .ant-table-empty {
		& .ant-table-placeholder {
			color: $color-text !important;
		}
		& .ant-table-cell {
			background-color: $color-white;
		}
	}
	&-loading {
		background-color: rgba($color: $color-gray, $alpha: 0.3);
		padding: 0 1rem;
		margin-bottom: 1rem;
		min-height: 6rem;
		display: flex;
		align-items: center;
		& .ant-skeleton-content {
			display: flex;
			align-items: center;
			column-gap: 2rem;
		}

		& .ant-skeleton-title {
			margin-top: 0;
			width: 18% !important;
		}

		& .ant-skeleton-paragraph {
			margin-top: 0 !important;
			width: 100%;
			@include display-flex;
			column-gap: 2rem;
			& li {
				margin-top: 0 !important;
				width: 6rem !important;
			}
		}
	}
}
