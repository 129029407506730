.mask {
    color: rgba(18, 18, 18, 0.5);
    /* opacity: 0.8; */
}

.helper {
    --reactour-accent: #5cb7b7;
    line-height: 1.3;
    color: rgba(18, 18, 18, 0.4);
    direction: ltr;
    & > *:nth-child(2) {
        justify-content: space-between;
        color: $color-primary !important;
        direction: rtl;
        svg {
            transform: rotateX(20);
        }
    }
    button {
        color: $color-primary !important;
    }
}

.WebTour {
    &__txtBox {
        text-align: right;
    }
    &__titile {
        font-weight: 600;
        font-size: 18px;
        padding-left: 2rem;
    }

    &__number {
        position: absolute;
        bottom: 2.5rem;
        left: 50%;
        transform: translateX(-50%);
    }
}
.reactour__close {
    right: auto !important;
}
