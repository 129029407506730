.profile__uploader {
	&--cover {
		cursor: pointer;
		width: 18.2rem;
		height: 18.2rem;
		border-radius: 50%;
		overflow: hidden;
		background-color: $color-gray;
		text-align: center;
	}
	&--cover {
		.imagePreview {
			width: 100%;
			height: 100%;
			object-fit: cover;
			image-rendering: -webkit-optimize-contrast;
			cursor: pointer;
		}
		.imagePreview--center {
			// position: relative;
			// top: 50%;
			// transform: translateY(-50%);
			width: 100%;
			height: 100%;
			object-fit: cover;
			image-rendering: -webkit-optimize-contrast;
			cursor: pointer;
		}
	}
}
.image__cover--perview {
	width: 100%;
	height: 100%;
}
// .ReactCrop {
// 	top: 50%;
// 	transform: translateY(-50%);
// }
.ReactCrop__image {
	max-width: 50rem;
	height: auto;
}
.ReactCrop .ReactCrop__crop-selection {
	border-radius: 50%;
}
.UploadProfile--uploadcoverbox {
	.UploadProfile__input {
		display: none;
	}
	.UploadProfile__label {
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: $color-white;
		border-radius: 50%;
		padding: 4px;
		color: $color-success;
	}
}
.SocialInput {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	&__p {
		width: 121px;
		text-align: left;
		@include respond(sm-laptop) {
			font-size: 14px;
		}
	}
}
.uploadProfile__box {
	grid-column: 1/-1;
	align-items: center;
	&-name {
		font-size: 18px;
	}
	&-id {
		font-size: 16px;
		margin-top: 10px;
	}
}
