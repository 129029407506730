.card-bg {
  // margin: 0 5rem;
  z-index: 10;
  position: relative;
  height: 38rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    110deg,
    rgba(#fff, 1) 0%,
    rgba(#fff, 1) 50%,
    rgba(#b4d236, 0.8) 50%,
    rgba(#b4d236, 0.8) 100%
  );
  box-shadow: 0px 2px 25px 2px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  overflow: hidden;

  &-title {
    position: absolute;
    top: 7rem;
    right: 16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 11rem;
    }
    h4 {
      font-size: 9rem;
      color: #fff;
    }
  }

  &-info {
    &-title {
      color: #022f47;
      font-size: 4.5rem;
      position: absolute;
      width: 100%;
      top: 2rem;
      right: 58rem;
    }

    &-content {
      position: absolute;
      top: 12.5rem;
      left: 20.5rem;
      width: 55rem;
      background-color: #45bda7c2;
      display: flex;
      padding: 2.5rem 1.5rem;
      border-radius: 8rem;

      img {
        width: 15rem;
        margin-left: 3rem;
      }

      div {
        h4 {
          font-size: 2.5rem;
        }
        span {
          font-size: 1.9rem;
        }
      }
    }

    .line {
      width: 2.5px;
      height: 100px;
      background-color: #012f47;
      position: relative;
      z-index: 100;
    }
  }
}
