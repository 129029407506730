.layout {
	&__dashboard {
		min-height: 100vh;
		background-color: $color-white;
		// max-width: 1920px;
	}

	&__header {
		z-index: 1000;
		background-color: $color-white;
		margin-bottom: 1rem;
		position: sticky;
		top: 0;
	}

	&__sidebar {
		z-index: 999;
		transition: all 200ms;
		// background-color: transparent;
		position: sticky;
		top: 9rem;
		height: 100%;
		// flex: auto;
		
	}

	&__wrapper {
		display: flex;
		direction: rtl;
		min-height: 0;
		flex: auto;
		position: relative;
	}

	&__content {
		margin: 0 5rem 2rem 11.2rem;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		padding: 0 5px 5px 2rem;
		@include respond(laptop) {
			margin-left: 5rem;
		}

		@include respond(sm-laptop) {
			margin: 3rem;
		}
		@include respond(tab-port) {
			margin: 2rem;
			padding-left: 5px;
		}
		@include respond(phone) {
			margin: 1rem;
		}
	}
}
