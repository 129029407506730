
.Example {
  margin: 0 auto;
  min-height: 59rem;
  direction: ltr;

  &__txtBox {
    direction: rtl;
  }
  &__title {
    font-size: 2rem;
    margin-bottom: 1rem;
    line-height: 4rem;
  }
  &__txt {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    line-height: 3.5rem;
    &-1 {
      line-height: 3.5rem;
      font-size: 2.5rem;
      color: #0dca78;
      margin-bottom: 1rem;
    }
  }
  &__codeeditor {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 3rem;
    margin-bottom: 3rem;
    overflow: hidden;
    border-radius: 15px;
    & > *:first-child {
      border-right: 1px solid #0dca78;
    }
    &-btnBox {
      background-color: #272822;
      border-bottom: 1px solid #fcca46;
      border-bottom: 1px solid #fcca46;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.5rem;
      p {
        display: inline-block;
        padding: 6px 1rem;
        color: #fcca46;
      }
      button {
        cursor: pointer;
        border-radius: 2rem;
        color: #ffffff;
        display: flex;
        align-items: center;
        &:focus {
          outline: none;
        }
      }
    }
    &-btnBox1 {
      background-color: #272822;
      border-bottom: 1px solid #fcca46;
      border-bottom: 1px solid #fcca46;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2rem;
      p {
        display: inline-block;
        padding: 0.5rem 1rem;
        color: #ffffff;
      }
      button {
        cursor: pointer;
        border-radius: 2rem;
        color: #ffffff;
        display: flex;
        align-items: center;
        &:focus {
          outline: none;
        }
      }
    }
    &-btnBox2 {
      background-color: #272822;
      border-bottom: 1px solid #fcca46;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2rem;
      padding-right: 10rem;
    }
    &-btncopy {
      background-color: transparent;
      border: 1px solid #ffffff;
      padding: 0rem 1rem;

      margin-right: 1rem;
      .fa-copy,
      .fa-check {
        color: #ffffff;
        margin-right: 0.5rem;
      }
    }
    &-btncopy1 {
      background-color: transparent;
      border: 1px solid #ffffff;
      padding: 0rem 1rem;
      .fa-copy,
      .fa-check {
        color: #ffffff;
        margin-right: 0.5rem;
      }
    }
    &-btndone {
      background-color: #0dca78;
      border: none;
      margin-right: 0.8rem;
      padding: 0rem 1rem;
      .fas {
        color: #ffffff;
        margin-right: 0.5rem;
      }
    }
    &-btndonedis {
      background-color: #707070;
      border: none;
      margin-right: 0.8rem;
      padding: 0rem 1rem;
      .fas {
        color: #ffffff;
        margin-right: 0.5rem;
      }
    }
  }
  &__TestBox {
    & > *:first-child {
      border-top: 1px solid #0dca78;
    }
  }
  &__Testtxt {
    color: #ffffff;
    background-color: #272822;
    border-bottom: 1px solid #fcca46;
    padding-bottom: 1rem;
    padding-left: 1rem;
    font-size: 1.2rem;
  }
  &__activbtn {
    background-color: #363738;
    color: #fcca46;
    border: none;
    padding: 0.5rem 1rem;
    border-bottom: 2px solid #fcca46;
    font-size: 1.5rem;
    &:focus {
      outline: none;
    }
  }

  &__disactivbtn {
    color: #202124;
    background-color: transparent;
    border: none;
    // border-right: 1px solid #202124;
    border-left: 1px solid #202124;
    border-bottom: 1px solid transparent;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
  }
  &__disactivbtn1 {
    color: white;
    background-color: transparent;
    border: none;
    // border-right: 1px solid $color-black;
    border-left: 1px solid #202124;
    border-bottom: 1px solid transparent;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
  }
}

.quizNumber__part {
  flex-direction: row-reverse;
  & > *:first-child {
    color: white;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  & > *:last-child {
    margin-right: 0.8rem;
    font-size: 2rem;
  }
  .faildquiz {
    color: #f50909;
  }
  .passquiz {
    color: #f3722c;
  }
  .goodquiz {
    color: #0dca78;
  }
}

.ExampleTextContainer-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 30px;
}
.ExampleTextContainer--text {
  text-align: right;
  font-size: 15px;
}
.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  border-bottom: 0.5px solid rgba($color: #777777, $alpha: 0.4);
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 7px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  &:hover,
  &.open {
    color: #255d4c;
  }

  &.open {
  }
}

.accordion-content {
}
.FaqContainer__heading {
  border-bottom: 0.5px solid rgba($color: #777777, $alpha: 0.4);
  margin-bottom: 30px;
}

.accordion-content {
}
.accordin--children {
  padding: 0 15px;
  direction: rtl;
  font-size: 12px;
  color: #222222;
  &--box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
  }
  &--title {
    display: flex;
    align-items: center;
    img {
      width: 25px;
      margin-left: 7px;
    }
  }
}
.test-case {
  color: #255d4c;
  font-weight: 500;
}
.ExampleTextContainer-defaulbody {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.ExampleTextContainer-btn {
  align-self: flex-start;
  display: flex;
  align-items: center;

  img {
    width: 15px;
    margin-right: 10px;
  }
}
.Example__codeeditor1-btndone {
  background-color: #fe8c42 !important;
}
