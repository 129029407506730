// Colors

// ------------ Primary ------------
$color-primary: #f68521;
$color-primary-light: #ffa44a;
$color-primary-dark: #cf6311;
$color-white: #fff;
$color-white-light: rgba(243, 243, 243, 0.7);
$color-white-light-1: rgba(243, 243, 243, 0.5);

// ------------ Background ------------
$color-blue: #022f47;
$color-blue-dark: #012f47;
$color-blue-light: #042f47;

$color-green: #47bea7;
$color-green-dark: #46bea7;

$color-section: #1a6577;

// ------------ black ------------
$color-black-light: #010101;
$color-black: #000;

//------------------gray----------------
$color-gray: #e5e5e5;
$color-dark-gray: rgba(0, 0, 0, 0.2);
$color-gray-dark: #bbbbbb;
// ------------ Text ------------
$color-text: #121212;
$color-text-light: rgba(
	$color: $color-text,
	$alpha: 0.7,
);
$color-disable: rgba(
	$color: $color-text,
	$alpha: 0.5,
);
$color-success: #329c00;
$color-error: #ff5a67;

$color-gradient: linear-gradient(140deg, #ef8019 30%, #f9a825 67%);

$shadow-button: drop-shadow(
	0 4px 6px
		rgba(
			$color: $color-primary,
			$alpha: 0.3,
		)
);

$shadow-backgroud: drop-shadow(
	0px 4px 30px
		rgba(
			$color: #000,
			$alpha: 0.25,
		)
);

// Fonts

$font-primary: 'vazir';
$font-primary-medium: 'vazir-medium';
$font-primary-bold: 'vazir-bold';

$fonts: (
	regular: $font-primary,
	medium: $font-primary-medium,
	bold: $font-primary-bold,
	extra: $font-primary-bold,
	black: $font-primary-bold,
);

$br-item: 2rem;
$br-box: 3rem;

// RESPONSIVE BREAKPOINTS
$bp-xl: 75em; // 1200px
$bp-lg: 62em; // 992px
$bp-md: 48em; // 768px;
$bp-sm: 36em; // 576px;
$bp-xs: 30em; // 480px;
