.MyCourseContainer__header {
	align-items: center;
	justify-content: space-between;
	padding: 10px 11px;
	@include respond(smallest-phone) {
		flex-direction: column;
		align-items: start;
        row-gap: 1.5rem;

		& .button {
			align-self: flex-end;
		}
	}
}
.mycourse_btn {
	justify-content: center;
}
.CourseStatusModal__grid {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-row-gap: 20px;
	border: 1px solid rgba($color: $color-gray, $alpha: 0.5);
	padding: 10px;
	border-radius: 15px;
	margin-top: 30px;
}
.CourseStatusModal__status {
	p {
		text-align: center;
	}
}
