.Footer {
  overflow: hidden !important;
  background-color: $color-blue;
  border-top-right-radius: 3.5rem;
  margin-top: 5rem;
  padding: 0rem 1rem 0 0;
  width: 100%;

  &__detail {
    width: 80%;
    padding-top: 5rem;
    @include respond(tablet) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__box {
    //Footer__box
    overflow: hidden;
    height: 39rem;
    max-height: 39rem;
    @include respond(sm-laptop) {
      height: 45rem;
      max-height: 45rem;
    }
    @include respond(tablet) {
      height: 70.8rem;
      max-height: 70.8rem;
    }
  }

  &__detailBox {
    @include respond(tablet) {
      justify-content: space-between;
      width: 70%;
      margin-left: auto;
      margin-right: 2rem;
    }
  }

  &__detailContainer {
    @include respond(sm-laptop) {
      margin-top: 2rem;
    }
    @include respond(tablet) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__bluBack {
    height: 47rem;
  }

  &__content {
    right: 8.688rem;
    top: 6.205rem;
    @include respond(sm-laptop) {
      width: 80%;
    }
    // @include respond(tablet) {
    //     top: 2.205rem;
    //     width: 100%;
    // }
    @include respond(sm-laptop) {
      top: 1.205rem;
      margin: 0 auto;
      right: 50%;
      transform: translateX(50%);
      flex-direction: column;
      justify-content: space-between;
      height: 90%;
      width: 80%;
    }
    @include respond(smallest-phone) {
      width: 90%;
    }
  }

  &__AboutUsBox {
    // width: 48.5rem;
    // height: 28.3rem;
    max-width: 30%;
    @include respond(tablet) {
      max-width: 90%;
      margin-bottom: 4rem;
    }
    background: $color-white-light;
    border-radius: 14.15rem;

    &-title {
      color: $color-blue;
      margin: 0 auto 2rem auto;
    }

    &-text {
      color: $color-black-light;
    }
  }

  &__logo {
    left: 9rem;
    @include respond(tablet) {
      left: 10rem;
    }

    img {
      width: 6rem;
      height: auto;
    }

    // @include respond(smallest-phone) {
    //     left: 12rem;
    //     img {
    //         width: 5rem;
    //         height: auto;
    //     }
    // }
  }

  &__SambolsBox {
    margin-left: 5rem;
    & > *:last-child {
      margin-right: -0.3rem;
    }

    & > * {
      height: 8.312rem;
      width: auto;
    }
  }

  &__accessBox {
    margin: 0 7.922rem 0 7.292rem;
    @include respond(tablet) {
      margin: 0;
    }
  }

  &__contenttxt {
    color: $color-white;
    margin-left: 7.922rem;
    @include respond(tablet) {
      margin-left: 0;
    }
  }

  &__accessTitle {
    color: $color-white;
  }

  &__accessList {
    color: $color-white;

    & > * {
      margin-bottom: 1.5rem;
      position: relative;
      margin-right: 1.5rem;

      &::before {
        content: "";
        position: absolute;
        width: 0.588rem;
        height: 0.588rem;
        border-radius: 50%;
        background-color: $color-primary;
        right: -1.808rem;
        top: 0.75rem;
      }
    }

    & > *:first-child {
      margin-top: 1rem;
    }
  }

  &__GreenLine {
    // bottom: 2.5rem;
    width: 68%;
    margin: 6rem auto 7rem auto;
    height: 0.18rem;
    background-color: $color-green;
    // right: 50%;
    // transform: translateX(50%);
  }

  $size: 10rem;

  &__greenPart {
    border-bottom-right-radius: 40rem;
    border-top-right-radius: 40rem;
    background-color: $color-green;
    width: 15%;
    height: 108%;
    left: 0;
    bottom: 0;
    margin-left: -8rem;
    border: 8px solid $color-white;
    @include respond(tablet) {
      bottom: 0;
      height: 60%;
      width: 40%;
      // top: auto;
      margin-left: -20%;
      border-bottom-right-radius: 150rem;
      border-top-right-radius: 150rem;
      background-color: $color-green;
    }
  }
}
