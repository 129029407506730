.upload {
	&__input {
		display: none;
	}
	&__preview {
		@include display-flex;
		column-gap: 1rem;
		background-color: rgba($color: $color-gray, $alpha: 0.6);
		padding: 1rem 1.5rem;
		border-radius: 1.5rem;

		&--icon {
			@include display-flex(center);
			border-radius: 50%;
			// background-color: $color-gray-dark;
		}
		& p {
			color: $color-text-light;
			display: -webkit-box;
			line-clamp: 1;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
		&--video {
		}
	}
	&__box {
		flex-wrap: wrap;
		gap: 2rem;
	}

	&__video {
		margin-top: 4rem;
		// margin-bottom: 1rem;
		min-height: 20rem;

		border-radius: 1.5rem;
		border: 2px dashed $color-gray-dark;
		transition: all 300ms;
		& .dropzone {
			@include d-flex-column(center);
			min-height: 100%;
			& svg {
				max-width: 12rem;
				opacity: 0.6;
			}

			& p {
				@include set-font(1.6rem, 600);
			}
		}

		&.accept {
			border-color: $color-success;
			background-color: rgba($color: $color-success, $alpha: 0.15);
		}
		&.reject {
			border-color: $color-error;
			background-color: rgba($color: $color-error, $alpha: 0.15);
		}

		&.uploading {
			& .dropzone {
				display: none;
			}
			& .upload__video--progress {
				display: block;
			}
			border-color: transparent;
		}

		&--progress {
			display: none;
			& .VideoPlayer__video {
				height: 100%;
			}

			& .ant-progress-text {
				// font-size: 14px;
				font-weight: 600;
			}
		}
	}
}
.label__button--primary {
	border-radius: 6rem;
	height: 40px;
	min-width: 12rem;
	padding: 4px 20px;
	@include display-flex(center);
	flex-direction: row-reverse;
	column-gap: 1.5rem;
	font-size: 16px;
	font-weight: 500;
	justify-content: space-around;
	color: $color-primary;
	border: 2px solid $color-primary;
	cursor: pointer;
	transition: all 0.3s;
	&:hover {
		background-color: $color-primary;
		color: white;
	}
}
