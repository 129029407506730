.AttributeItem {
	align-items: center;
	margin: 5px 0;
	&__Bg {
		background-color: rgba($color: $color-gray, $alpha: 0.5);
		border-radius: 15px;
		padding: 7px 14px;
		width: 50rem;
		font-size: 14px;
	}
	&__box {
		// border: 1px solid $color-gray;
		// box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.1);
		// padding: 1rem {
		// 	right: 2rem;
		// 	bottom: 2rem;
		// }
		// border-radius: 1.5rem;
		margin-bottom: 3rem;
		
	}
}
.PropertyForm {
	align-items: flex-end;
	column-gap: 5px;
	& .input {
		flex-basis: 50rem;
		position: relative;
		margin-bottom: 1rem;
	}
	& .input__message {
		position: absolute;
		bottom: -2rem;
		right: 0;
	}
	& .button__icon{
		margin-bottom: 1rem;
	}
}
.atrr__title {
	font-size: 1.6rem;
}
