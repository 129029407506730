.ContentFirstPhase {
	flex: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&__row {
		display: grid;
		grid-template-columns: 1fr 1fr max-content;
		margin: 10px;
		grid-gap: 20px;
	}
	&__box {
		grid-column: 1/-1;
		background: #ffffff;
		box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
		border-radius: 15px;
		padding: 18px;
		margin: 20px 10px;
		&--tilte {
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 15px;
		}
	}
}

.ContentSecondPhase.ContentTest {
	padding: 44px 28px;
	.Quize-des {
		font-size: 12px;
		margin: 10px 0;
	}
}
