.button {
	border-radius: 6rem;
	height: 40px;
	min-width: 12rem;
	padding: 4px 20px;
	@include display-flex(center);
	flex-direction: row-reverse;
	column-gap: 1.5rem;
	font-size: 16px;
	font-weight: 500;
	// justify-content: space-around;
	& svg {
		max-width: 20px;
		max-height: 20px;
	}

	&.disabled {
		user-select: none;
		cursor: not-allowed;
		color: $color-gray-dark;
		background-color: $color-gray;
		border-color: $color-gray;
		filter: none;
		box-shadow: none;
		pointer-events: none;
	}

	&.large {
		height: 50px;
		font-size: 2rem;
		& svg {
			max-width: 24px;
			max-height: 24px;
		}
	}

	&__default {
		border: 2px solid $color-primary;
		color: $color-primary;

		&:hover {
			background-color: $color-primary;
			color: $color-white;
			filter: $shadow-button;
		}
	}

	&__primary {
		filter: $shadow-button;
	}

	&__gradient {
		background: $color-gradient;
		min-width: 15rem;
		color: $color-white;
		border: 0;
		&.disabled {
			background: $color-gray;
			&:hover {
				background: $color-gray;
			}
		}
		&:hover,
		&:active,
		&:focus {
			background: $color-gradient;
			color: $color-white;
			filter: $shadow-button;
		}
	}

	&__text {
		color: $color-primary;
		flex-direction: row;
		column-gap: 1rem;
		position: relative;
		overflow: hidden;
		font-size: 14px;
		&.disabled {
			color: $color-gray-dark;
			background-color: transparent;
			border-color: transparent;
			&::after {
				content: none;
			}
			&:hover,
			&:active,
			&:focus {
				color: $color-gray-dark;
				background-color: transparent;
				border-color: transparent;
			}
		}
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			background-color: currentColor;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: all 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
		}

		&.success {
			color: $color-success !important;
		}
		&:hover,
		&:active,
		&:focus {
			color: $color-primary;
			background-color: transparent;

			&::after {
				opacity: 0.15;
			}
		}
	}
	&__icon {
		@include display-flex(center);
		border: 0;
		overflow: hidden;
		min-width: 40px;
		width: 40px;
		height: 40px;
		padding: 8px;
		border-radius: 50%;
		position: relative;

		&.ant-tooltip-disabled-compatible-wrapper {
			opacity: 0.5;
			display: flex !important;
		}

		&.success {
			color: $color-success !important;
		}

		& svg {
			max-width: 22px;
			max-height: 22px;
		}
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			background-color: currentColor;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: all 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
		}

		&:hover,
		&:active {
			color: currentColor;
			background-color: transparent;

			&::after {
				opacity: 0.2;
			}
		}
	}
}
