.WorkExperinceDone {
	&__header {
	}
	&__title {
	}
	&__middle {
		align-items: center;
		margin: 10px 0;
		span {
			margin: 0 5px;
		}
		& .line {
			position: relative;

			@include display-flex;

			&::after {
				content: '';
				position: relative;
				width: 1.5rem;
				height: 1px;
				background-color: $color-black;
                margin: 0 1rem;
			}
		}
	}
	&__des {
		width: 75%;
		@include respond(small-tablet) {
			width: 100%;
		}
	}
}
.WorkExperienceForm {
	&__row {
		justify-content: space-between;
		grid-template-columns: 1fr 1fr 0.5fr;
		margin: 20px 0;
	}
}
