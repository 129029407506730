.breadcrumbs {
	.ant-breadcrumb {
		display: flex;
		@include respond(tablet) {
			font-size: 12px;
		}
		span {
			display: flex;
			align-items: center;
		}
	}
}
