.Quize__page{
    &--title{
        font-size: 18px;
        text-align: center;
        margin: 38px 0;

    }
    &--form{
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
}